import { axiosInstance as axios } from '../lib/axios';

export const getImageModals = async () => {
  try {
    const resposta = await axios.get('/content/modals?populate=*');
    return { data: resposta.data.data, error: null };
  } catch (err) {
    return { data: [], error: 'Erro ao carregar o conteúdo.' };
  }
};

export const getAlerts = async () => {
  try {
    const response = await axios.get('/content/messages?populate=*');
    const data = response.data.data.map(item => item.attributes);
    return { data, error: null };
  } catch (err) {
    return { data: [], error: 'Erro ao carregar as mensagens.' };
  }
};
