
import { CAMPAIGN_CREATE, CAMPAIGN_DETAIL } from '../actionTypes/campaign'

export const BASE_CAMPAIGN = {
  requester_email: "",
  creation_date: "",
  flow: "",
  campaign: {
    name: "",
    cashback_percentage: null,
    partner_cashback_percentage: null,
    loyalty_points: null,
    start_date: "",
    start_time: "00:01",
    end_date: "",
    end_time: "23:59",
    minimum_order_value: null,
    recurrence_days: [],
    skus: [],
    payment_methods: []
  },
  stores: {
    economic_group: [],
    head_offices: [],
    branches: []
  }
}

const INITIAL_STATE = {
  create: BASE_CAMPAIGN,
  detail: {}
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CAMPAIGN_CREATE:
      return { ...state, create: action.payload }
    case CAMPAIGN_DETAIL:
      return { ...state, detail: action.payload }
    default:
      return state
  }
}
